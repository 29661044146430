exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-galerija-js": () => import("./../../../src/pages/galerija.js" /* webpackChunkName: "component---src-pages-galerija-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-o-nas-js": () => import("./../../../src/pages/o-nas.js" /* webpackChunkName: "component---src-pages-o-nas-js" */),
  "component---src-pages-pogoji-uporabe-js": () => import("./../../../src/pages/pogoji-uporabe.js" /* webpackChunkName: "component---src-pages-pogoji-uporabe-js" */),
  "component---src-pages-programi-js": () => import("./../../../src/pages/programi.js" /* webpackChunkName: "component---src-pages-programi-js" */),
  "component---src-pages-trenerji-js": () => import("./../../../src/pages/trenerji.js" /* webpackChunkName: "component---src-pages-trenerji-js" */),
  "component---src-pages-vpis-js": () => import("./../../../src/pages/vpis.js" /* webpackChunkName: "component---src-pages-vpis-js" */)
}

